<template>
<div style="max-width: 800px">

  <div class="card-box-full">
    <div class="card-box-header">
      Live Domains <span style="margin-left: 6px; font-size: 10px; font-weight: 600">average times seen per minute</span>
    </div>

    <!-- ----------------------------------------------------------------- -->
    <div v-if="errorMessage">
      <div class="alert alert-danger" style="margin-top: 15px; margin-bottom: 5px" role="alert">{{ errorMessage }}</div>
    </div>

    <div v-if="isRefreshingRadar && results.length == 0">
      <div class="spinner-border spinner-border-sm text-primary" role="status" style="margin-top: 10px">
        <span class="sr-only"></span>
      </div>
    </div>

    <div v-if="results.length > 0" style="margin-top: 5px">

      <transition-group class="results-group" name="results-list" tag="ul">
        <li v-for="result in results" :key="result.value" class="clickable-row">
          <table width="100%" style="cursor: pointer" @click="openDomainProfile(result.value)">
            <tr>
              <td width="120" align="right">
                  <div style="background-color: transparent; text-overflow: ellipsis; max-width: 110px; overflow: hidden; font-size: 11px; font-weight: 600; margin-right: 5px; height: 20px; word-break: break-all">{{ result.value }}</div>
              </td>
              <td>
                <transition name="result-fade" mode="out-in">
                  <span :key="result.total" class="badge badge-pill badge-info" v-bind:style="resultStyle(result)">&nbsp;</span>
                </transition>
                <span class="badge badge-pill badge-light" style="margin-left: 5px">{{ parseInt(result.per_minute).toLocaleString() }}</span>
              </td>
            </tr>
          </table>

        </li>
      </transition-group>

    </div>

    <div v-else>
      <div class="alert alert-warning" style="margin-top: 15px; margin-bottom: 5px" role="alert">No results were returned</div>
    </div>

  </div>

</div>
</template>

<style>
.results-group {
  list-style: none;
  margin-left: 0px;
  padding-left: 0px;
  margin-bottom: 0px;
}

.results-list-move {
  transition: transform 1.0s;
}

.result-fade-enter-active {
  transition: all 0.2s ease;
}

.result-fade-leave-active {
  transition: all 0.2s ease;
}

.result-fade-enter {
  /* transform: scale(0.5); */
  opacity: 0.5;
}

.result-fade-enter-to {
  /* transform: scale(1); */
  opacity: 1.0;
}

.result-fade-leave {
  /* transform: scale(1.0); */
  opacity: 1;
}

.result-fade-leave-to {
  /* transform: scale(0.5); */
  opacity: 0.5;
}
</style>

<script>
export default {
  name: 'Domains-Live',
  data() {
    return {
      currentRadar: this.defaultRadar(),
      errorMessage: null,
      isRefreshingRadar: false,
      maxTotal: 0,
      refreshTimer: null,
      results: []
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    defaultRadar() {
      return {
        id: 'domains.country.global',
        group: 'domains',
        category: 'country',
        tag: 'global'
      }
    },
    //--------------------------------------------------------------------------
    openDomainProfile(domain) {
      var url = "http://admin.robocalls.ai/#/sms-profile/domain/" + domain;
      window.open(url, '_blank');
    },
    //--------------------------------------------------------------------------
    processRadar(response) {
      // Make sure we received results
      if (!response || !response.results) {
        this.errorMessage = "No results were returned";
        return;
      }

      // We only want a maximum of 25 results
      if (response.results.length > 25) {
        response.results = response.results.slice(0, 25);
      }

      // Sort the results
      response.results.sort(function(a, b) {
        if (a.per_minute < b.per_minute) {
          return 1;
        } else if (a.per_minute > b.per_minute) {
          return -1;
        } else {
          return a.value.localeCompare(b.value);
        }
      });

      var max = 0;
      for (var x = 0; x < response.results.length; x++) {
        if (response.results[x].per_minute > max) {
          max = response.results[x].per_minute;
        }
      }
      this.maxTotal = max;
      this.results = response.results;
    },
    //--------------------------------------------------------------------------
    refreshRadar() {
      if (this.isRefreshingRadar) {
        return;
      } else {
        this.isRefreshingRadar = true;
      }

      // Fetch the data
      var that = this;
      var method = "GET";
      var url = "https://sms-radar.platform.robocalls.ai/live?group=" + encodeURIComponent(this.currentRadar.group) + "&category=" + encodeURIComponent(this.currentRadar.category) + "&tag=" + encodeURIComponent(this.currentRadar.tag);
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isRefreshingRadar = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the response
            try {
              var result = JSON.parse(body);
              that.processRadar(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isRefreshingRadar = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isRefreshingRadar = false;
          that.errorMessage = error;
        }
      })
    },
    //--------------------------------------------------------------------------
    resultStyle(result) {
      if (this.maxTotal <= 0) {
        this.maxTotal = 1;
      }
      var width = (result.per_minute / this.maxTotal * 90);
      return {
        width: width + "%",
        textAlign: "right"
      };
    }
  },
  mounted() {
    this.refreshRadar();
    var self = this;
    this.refreshTimer = setInterval(function() {
      self.refreshRadar();
    }, 1000);
  },
  beforeDestroy() {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
      this.refreshTimer = null;
    }
  }
}
</script>
